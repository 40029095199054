import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "../../../config/axios";
const NotificationDropdown = (props) => {
    const [count, setcount] = useState(0);
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    function getnotificationnumber() {
        let uparr = [];
        axios
            .get("/api/notification", {
                headers: {
                    token: `${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                uparr = res.data.notifications.filter((not) => {
                    return !not.read;
                });
                setcount(uparr.length);
            });
        return count;
    }
    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: "#213d30",
            color: "white",
        },
    }))(Badge);
    return (
        <StyledBadge badgeContent={getnotificationnumber()}>
            <i className="bx bx-bell" style={{ fontSize: "25px" }}></i>
        </StyledBadge>
    );
};

export default NotificationDropdown;
