export const SIGNUP_EMAIL = 'SIGNUP_EMAIL';
export const SIGNUP_GOOGLE = 'SIGNUP_GOOGLE';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PASSWORD_SET_SUCCESS = "PASSWORD_SET_SUCCESS";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE';
export const NEW_PAGE = 'NEW_PAGE';
export const FORGOT_PASSWORD="FORGOT_PASSWORD";

const initialState = {
    error: "",
    loading: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            }
            break;
            case FORGOT_PASSWORD:
                state={
                    loading: false,
                    email: action.payload,
                }
                break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break;
        case SIGNUP_EMAIL:
            state = {
                loading: true,
                email: action.payload,
            }
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case API_ERROR:
            if (action.payload === undefined) {
                action.payload = "Something went wrong...Please try again later"
            }
            state = { ...state, error: action.payload, loading: false };
            break;
        case PASSWORD_SET_SUCCESS:
            state = { ...state }
            break;
        case PASSWORD_RESET_SUCCESS:
            state = { ...state }
            break;
        case NEW_PAGE:
            state = { ...initialState }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default auth;