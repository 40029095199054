import React from "react";
import { Redirect } from "react-router-dom";

//Components
import SetPassword from "../pages/Authentication/SetPassword";
import Login from "../pages/Authentication/Login";
import SignUP from '../pages/Authentication/SignUp';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import Verification from "../pages/Authentication/Verification";
import ResetVerification from "../pages/Authentication/ResetVerification";
import PublicProfile from '../pages/StudentPublicProfile';

import ResetPassword from "../pages/Authentication/ResetPassword";
//College Components 
import CollegeProfile from '../pages/College/Profile';
import CollegeOnboarding from '../pages/College/CollegeOnborading';
import CollegeDashboard from '../pages/College/CollegeDashboard';
import AccountSetting from '../pages/AccountSetting';
import Notifications from "../pages/Notifications/Notifications";
const userRoutes = [
    { path: "/collegeProfile", component: CollegeProfile },
    { path: "/collegeOnboarding", component: CollegeOnboarding },
    { path: "/collegeDashboard", component: CollegeDashboard },
    { path: "/setting", component: AccountSetting },
    {path:"/notifications",component:Notifications},
    { path: "/public/profile/:id", exact: true, component: (props) => <PublicProfile {...props} /> },

    // this route should be at the end of all other routes
    {
        path: "/", exact: true, component: () =>
            localStorage.getItem("token") ?
                localStorage.getItem("onboarding") == 'true' ?
                    <Redirect to="/collegeDashboard" />
                    :
                    <Redirect to="/collegeOnboarding" />
                :
                <Redirect to="/login" />
    }
];

const authRoutes = [
    { path: "/setpassword", component: SetPassword },
    { path: "/resetpassword/", component: ResetPassword },
    { path: "/login", component: Login },
    { path: "/forgotpassword", component: ForgotPassword },
    { path: "/verification", component: Verification },
    { path: "/signup", component: SignUP },
    { path: "/passwordreset", component: ResetVerification }

]

export { userRoutes, authRoutes };