import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Redux
import { withRouter, Link } from "react-router-dom";
import { getOrganizationDetails } from "../../../redux/actions/profile";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApiService from "../../../config/restapi";
// users
import default_company_logo from "../../../assets/images/d-company-logo.png";
import profile_default from "../../../assets/images/users/blank_profile.svg";
const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [profile, setProfile] = useState({
    college: {},
    college_course: [],
  });

  useEffect(() => {
    // props.getOrganizationDetails();
    ApiService.getProfile().then((res) => {
      if (res && res.status === 200 && res.data) {
        setProfile(res.data.college);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            src={profile&&
              profile.college && profile.college.logo_url
                ? profile.college.logo_url
                : default_company_logo
            }
            alt=""
            height="20"
            className="avatar-sm img-thumbnail rounded-circle"
          />
          {/* <img className="rounded-circle header-profile-user" src={default_company_logo} alt="Header Avatar" /> */}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/collegeProfile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile
          </DropdownItem>
          {profile.login_type === "email" && ( 
              <DropdownItem tag="a" href="/setting">
              <i className="bx bx-cog font-size-17 align-middle mr-1"></i>Settings
              </DropdownItem>
          )}
          
          <div className="dropdown-divider"></div>
          <Link
            to="#"
            className="dropdown-item"
            onClick={() => {
              localStorage.removeItem("token");
              props.history.push("/login");
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOrganizationDetails }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
);
