import React, { useState } from "react";

import { connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";

// Import menuDropdown
//import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
import logo from "../../assets/images/logoz.png";

// Redux Store
import { toggleLeftmenu, changeSidebarType } from "../../redux/actions/layout";
import Common from "../../helpers/common";

const Header = (props) => {
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [studentlist, setStudentList] = useState("");
  function startSearch(value) {
    ApiService.getStudentsList(value).then((res) => {
      if (res && res.status === 200) {
        setStudentList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  let history = useHistory();
  function toStudents(id) {
    history.push(`/public/profile/${id}`);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="68" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="19" />
                </span>
              </Link>
            </div>

            <form
              className="app-search d-none d-lg-block"
              // onBlur={() => {
              //   setStudentList("");
              // }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Students By Email"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                    if (search) {
                      startSearch(e.target.value);
                    } else {
                      setStudentList("");
                    }
                  }}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              <div
                className={
                  search && studentlist
                    ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                {studentlist.students ? (
                  <ul
                    style={{
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    {studentlist &&
                      studentlist.students &&
                      studentlist.students.length > 0 &&
                      studentlist.students
                        .filter((item) => {
                          if (search == "") {
                            console.log("IF");
                          } else if (
                            item.email
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          console.log(item);
                          if (item.is_active) {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  console.log("HI");
                                  toStudents(item.UserId);
                                }}
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.title ? item.title : item.email}
                              </li>
                            );
                          } else {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  console.log("HI");
                                  // toStudents(item.id);
                                  toastr.error(
                                    "User invited but not active yet!",
                                    "",
                                    {
                                      timeOut: 2000,
                                    }
                                  );
                                }}
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.title ? item.title : item.email}
                              </li>
                            );
                          }
                        })}
                  </ul>
                ) : (
                  <ul
                    style={{
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    <li
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {studentlist.message}
                    </li>
                  </ul>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => setIsSearch(!isSearch)}
                // onBlur={() => setIsSearch(!isSearch)}
                // onChange={(e) => {
                //   setSearch(e.target.value);
                //   console.log(e.target.value);
                //   if (search) {
                //     startSearch();
                //   } else {
                //     setStudentList("");
                //   }
                // }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form
                  className="p-3"
                  // onBlur={() => {
                  //   setStudentList("");
                  // }}
                >
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                        onChange={(e) => {
                          e.target.value = Common.trimStart(e.target.value);
                          setSearch(e.target.value);
                          console.log(e.target.value);
                          if (search) {
                            startSearch(e.target.value);
                          } else {
                            setStudentList("");
                          }
                        }}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isSearch && search && studentlist
                        ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                  >
                    {studentlist.students ? (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        {studentlist &&
                          studentlist.students &&
                          studentlist.students.length > 0 &&
                          studentlist.students
                            .filter((item) => {
                              if (search == "") {
                                console.log("IF");
                              } else if (
                                item.email
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => {
                              console.log(item);
                              if (item.is_active) {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      console.log("HI");
                                      toStudents(item.UserId);
                                    }}
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.title ? item.title : item.email}
                                  </li>
                                );
                              } else {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      console.log("HI");
                                      // toStudents(item.id);
                                      toastr.error(
                                        "User invited but not active yet!",
                                        "",
                                        {
                                          timeOut: 2000,
                                        }
                                      );
                                    }}
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.title ? item.title : item.email}
                                  </li>
                                );
                              }
                            })}
                      </ul>
                    ) : (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        <li
                          style={{
                            padding: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {studentlist.message}
                        </li>
                      </ul>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="my-auto f-20 mx-3 ">
              <Link
                to="/dashboard"
                style={{ fontWeight: "500" }}
                className="no-hover"
              >
                Jobs
              </Link>
            </div>

            <div className="my-auto f-20 mx-3 ">
              {" "}
              <Link
                to="/assessment"
                style={{ fontWeight: "500" }}
                className="no-hover"
              >
                Assessments
              </Link>
            </div>
            <div className="my-auto f-20 mx-3 " style={{ fontWeight: "500" }}>
              Courses
            </div>
            <div className="my-auto f-20 mx-3">
              <Link
                to="/resumebuilder"
                style={{ fontWeight: "500" }}
                className="no-hover"
              >
                Resume Builder
              </Link>
            </div>

            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, { toggleLeftmenu, changeSidebarType })(
  Header
);
