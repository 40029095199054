import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Form, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import TextArea from '../../../components/TextArea';
import Select from 'react-select';
import { Domains, CollegeDegrees } from '../../../helpers/constant_variables.mjs';
import toastr from "toastr";
import "toastr/build/toastr.min.css"
import edit_icon from '../../../assets/images/pencil.png'
import post_job_icon from '../../../assets/images/job_age_icon.png';
import { coursesdet } from '../../../helpers/constant_variables.mjs';
import { WithContext as ReactTags } from 'react-tag-input';
const AboutCompany = (props) => {
    
    const KeyCodes = {
        tab: 9,
        enter: 13,
    };

    const [skillsMustArray, setSkillsMustArray] = useState([]);
    const handleDelete = (i) => {
        const tags = [...skillsMustArray];
        setSkillsMustArray([...tags.filter((tag, index) => index !== i)])

    }

    const handleAddition = (tag) => {
        const tempTag = [...skillsMustArray, tag];
        setSkillsMustArray([...tempTag]);
        console.log(tempTag);
        props.OnchangeCallBack(tempTag, "courses_tag")
    }

    const delimiters = [KeyCodes.tab, KeyCodes.enter];
    const [descriptioncharcomplete, setDescriptioncharcomplete] = useState(0);
    const [showError, setShowError] = useState(false);
    const [degree, setDegree] = useState();
    const [course, setCourse] = useState();
    const [number, setnumber] = useState(0);
    const [degreedata, setdegreeData] = useState([]);
    const [suggestiona, setsuggestionsa] = useState();

    useEffect(() => {
       let datarequired = props.degrees.map((degree)=>{
           return {
               degree:{label:degree.degree},
               number:degree.total_students,
               skillsMustArray:degree.course.map((item)=>{
                   return{
                       id:item,
                       label:item
                   }
               })
           }
       })
       setdegreeData(datarequired);
    }, [])

    function onChangeTextareaHandler(event) {
        // const charCount = event.target.value.length;
        var regex = /\s+/gi;
        var WordCount = event.target.value === "" ? 0 : event.target.value.trim().replace(regex, ' ').split(' ').length;
        if (WordCount <= 500) setDescriptioncharcomplete(WordCount);
        props.OnchangeCallBack(event.target.value, 'description');
    }
    function adddegree() {
        if (!skillsMustArray || !degree || !number) {
            setShowError(true);
            toastr.error("Fields cannot be empty");
            return;
        }
        let tempdata = [...degreedata];
        tempdata.push({ degree, skillsMustArray, number });
        setdegreeData(tempdata);
        setDegree("");
        setSkillsMustArray([]);
        setnumber(0);
        setShowError(false);
        console.log(tempdata);

        const updatedtempdata = tempdata.map(data => {
            const updatedMustSkills = data.skillsMustArray.map((item) => {
                return item.label;
            });

            return ({ degree: data.degree.label, course: updatedMustSkills, total_students: data.number }
            )
        })
        props.OnchangeCallBack(updatedtempdata, "extra");
    }
    function handleInputChange() {
        // setsuggestionsa([...suggestiona]);
    }
    function editinfo(data, index) {
        setDegree(data.degree);
        setSkillsMustArray(data.skillsMustArray);
        setnumber(data.number);
        let tempdata = [...degreedata];
        tempdata.splice(index, 1);
        setdegreeData(tempdata);
        const updatedtempdata = tempdata.map(data => {
            const updatedMustSkills = data.skillsMustArray.map((item) => {
                return item.label;
            });
            return ({ degree: data.degree.label, course: updatedMustSkills, total_students: data.number }
            )
        })
        console.log(updatedtempdata)
        props.OnchangeCallBack(updatedtempdata, "extra");
    }

    return (
        <TabContent activeTab={props.activeTab} className="twitter-bs-wizard-tab-content pt-0 degree_courses">
            <TabPane tabId={2} className="about-company text-left">
                <Row>
                    {degreedata && degreedata.map((data, index) => {
                        return (

                            <Col lg="12" className="my-1">
                                <div className="added-row">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex ">
                                            <span><h6 className="font-size-18 pr-3" style={{ borderRight: "1px solid black" }}>{data.degree.label}</h6></span>
                                            <span className="font-size-14 pl-3">{data.number} students</span>
                                        </div>
                                        <img src={edit_icon} onClick={() => editinfo(data, index)} style={{ width: "16px", height: "16px" }} alt="pencil_icon"></img>
                                    </div>
                                    <div className="mt-2">
                                        {data.skillsMustArray.map(dat => {
                                            return (
                                                <span className="circled-box px-2 py-1 mr-1">
                                                    {dat.label}
                                                </span>
                                            )
                                        })}

                                    </div>
                                </div>
                            </Col>


                        )
                    })}
                </Row>

                <Form>
                    <Row>
                        <Col lg="12">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Degree</Label>
                                <Select
                                    className="select-container"
                                    value={degree}
                                    onChange={(selectedOption) => {
                                        setDegree(selectedOption);
                                        setsuggestionsa(selectedOption.courses)
                                        setSkillsMustArray([])
                                        props.OnchangeCallBack(selectedOption.label, "degree")
                                    }}
                                    options={CollegeDegrees}
                                    isSearchable
                                    placeholder="Select"
                                />
                                {showError && !degree && <label style={{ "margin-bottom": "0", "color": "red" }}>Please enter your Designation.</label>}

                                {/* <Input type="text" value={props.details.size || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'size')} /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Courses</Label>
                                {/* <Input type="text" value={props.details.industry || ''} className="form-control" onChange={(event) => props.OnchangeCallBack(event.target.value, 'industry')} /> */}
                                {/* <Select
                                    className="select-container"
                                    value={course}
                                    onChange={(selectedOption)=>{
                                        setCourse(selectedOption);
                                        props.OnchangeCallBack(course,"course")}}
                                    options={Domains}
                                    isSearchable
                                    innerProps={{ className: "test" }}
                                    placeholder="Select"
                                /> */}
                                <ReactTags

                                    tags={skillsMustArray}
                                    suggestions={suggestiona}
                                    delimiters={delimiters}
                                    handleDelete={handleDelete}
                                    handleAddition={handleAddition}
                                    minQueryLength={0}
                                    allowDragDrop={false}
                                    handleInputChange={handleInputChange}
                                    placeholder='Add Courses'
                                    inputFieldPosition="top"
                                    labelField={'label'}
                                    renderSuggestion={({ label }, query) => <div style={{ marginLeft: '0px' }}>{label}</div>}
                                />
                                {showError && skillsMustArray.length === 0 && <label style={{ "margin-bottom": "0", "color": "red" }}>Skills field cannot be empty.</label>}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-firstname-input1">Total students registered for the degree </Label>
                                <Input type="number" value={number} className="form-control" onChange={(event) => { setnumber(event.target.value); props.OnchangeCallBack(event.target.value, "total_students"); }} />
                                {showError && number === 0 && <label style={{ "margin-bottom": "0", "color": "red" }}>Enter the number of students registered.</label>}

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <div className="float-right">
                            <button
                                className=' btn btn-outline-shadow waves-effect waves-light w-md'
                                type="button"
                                onClick={() => adddegree()}
                            ><img className='mr-2' src={post_job_icon} alt='job-post' /> ADD THIS DEGREE
                            </button>
                        </div></Row>
                </Form>
            </TabPane>
        </TabContent>
    )
}

export default AboutCompany;