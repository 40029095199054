import React, { useState,useEffect } from 'react';

import { Row, Col, CardBody, Card, Alert,Container, Label, InputGroup, InputGroupAddon } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

// image
import rightIcon from "../../assets/images/right.png"
import api from '../../config/axios';
import {ResetPassword} from '../../redux/actions/auth';
import text_logo from '../../assets/images/zunavish_auth_text.png';

 const Resetpassword = (props) => {

        const [password, ResetPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [pwdElementtype, setPwdElementType]= useState('password');
        const [confirmPwdElementType, setConfirmPwdElementType]= useState('password');
        const [ confirmpassErr, setConfirmPassErr ] = useState('This field is required.');
        ///const [ passErr, setPassErr ] = useState('This field is required.');
        const [showRightIcon, setShowRightIcon] = useState(false);

        // handleValidSubmit
        function  handleValidSubmit(event, values) {
            //props.loginUser(values, props.history);
            //add path to url
            
            const query = new URLSearchParams(props.location.search);
           props.ResetPassword(password,confirmPassword, query.get("token"),props.history);
        }   

        function handlePasswodType(type ){
            let newType = type === 'text' ? 'password' : 'text';
            setPwdElementType(newType);
        }
        function handleConfirmPasswodType(type ){
            let newType = type === 'text' ? 'password' : 'text';
            setConfirmPwdElementType(newType);
        }

        function onConfrmPasswordChange(target){
            setConfirmPassword(target.value); 
            if(target.value && password !== target.value){
                setConfirmPassErr('Password did not match.');
            }else{
                setConfirmPassErr('This field is required.');
            }
        }

        
        function validateNumericUpperCase(value){
            let regex = /(?=.*[A-Z])(?=.*[0-9])/;
            if (!value.match(regex)) {
                return false;
            }
            return true;
        }

        function onChangePassword(target){
            ResetPassword(target.value); 
            if(target.value && target.value.length >= "8" && validateNumericUpperCase(target.value)){
                // setShowRightIcon(true);
            }else{
                setShowRightIcon(false);
            }
        }
        return (
            <React.Fragment>
                <div className="account-pages">
                    <div className="float-left left-section">
                        <Row className='w-100'>
                            <Col className="col-12">
                                <div className="pl-4 pb-2">
                                    <h5 className="font-size-18 font-weight-bold"> RESET PASSWORD </h5>
                                </div>
                                <div className="pl-4">
                                    <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handleValidSubmit(e,v) }}>
                                                {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
                                            <AvGroup>
                                                <Label for="password">Password</Label>
                                                <InputGroup>
                                                    <AvInput name="password"  type={pwdElementtype} placeholder="Enter Password" required
                                                        onChange={({ target }) => { onChangePassword(target); }} 
                                                        validate={{
                                                            // required: { value: true },
                                                            // minLength: { value: 8 },
                                                            // pattern: {
                                                            //     value: "(?=.*[A-Z])(?=.*[0-9])"
                                                            // }
                                                            }}
                                                    />
                                                    <InputGroupAddon onClick={()=>handlePasswodType(pwdElementtype)} addonType="append">
                                                        {pwdElementtype === 'text' ? 
                                                        <i className="mdi mdi-eye-off-outline font-size-20"></i>  : <i className="mdi mdi-eye-outline font-size-20"></i> 
                                                        }                                                                                                                           
                                                    </InputGroupAddon>     
                                                    <AvFeedback>Invalid Password</AvFeedback>
                                                </InputGroup>                                                        
                                            </AvGroup>   
                                            <AvGroup className="font-weight-light pass-err-desc">
                                                <p className="mb-0 font-weight-200 roboto-light text-muted"> Password must be </p>  
                                                {showRightIcon ? 
                                                 <ul className="list-unstyled">
                                                 <li> <img alt="no image1" src={rightIcon}></img> Minimum of 8 characters</li>
                                                 <li><img alt="no image2" src={rightIcon}></img>Atleast 1 Uppercase</li>
                                                 <li><img alt="no image3" src={rightIcon}></img>Atleast 1  Numeric</li>
                                             </ul>     
                                             :
                                             <ul className="pl-3">
                                                 <li>Minimum of 8 characters</li>
                                                 <li>Atleast 1 Uppercase</li>
                                                 <li>Atleast 1  Numeric</li>
                                             </ul>      
                                                }    
                                                                            
                                            </AvGroup>   
                                            <AvGroup>
                                                <Label for="cpassword">Confirm Password</Label>
                                                <InputGroup>
                                                    <AvInput name="cpassword"  type={confirmPwdElementType} placeholder="Enter Confirm Password" 
                                                        onChange={({ target }) => { onConfrmPasswordChange(target);}} 
                                                        validate={{
                                                            required: { value: true },
                                                            match: { value: "password" }
                                                            }}
                                                        />
                                                    <InputGroupAddon onClick={()=>handleConfirmPasswodType(confirmPwdElementType)} addonType="append">
                                                    {confirmPwdElementType === 'text' ?
                                                        <i className="mdi mdi-eye-off-outline font-size-20"></i> : <i className="mdi mdi-eye-outline font-size-20"></i>
                                                    }                                                                                                                           
                                                    </InputGroupAddon>
                                                    <AvFeedback>{confirmpassErr} </AvFeedback>
                                                    { password && password === confirmPassword ? <div className="pw-match-success">Passwords match.</div> : ''}
                                                </InputGroup>                                                        
                                            </AvGroup>
                                            <div className="mt-3 text-left">
                                                <button className="btn bg-green waves-effect waves-light w-md" type="submit">Reset Password</button>
                                            </div>  
                                    </AvForm>    
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div  className="float-left right-section">
                        <div className='text-container'>
                            <div className='company-text-bold'>
                                <img src={text_logo} alt='text'/>
                            </div>
                            <div className='mt-2 text-center'>
                                    vivify your business and People
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
          );
        }
        const mapStatetoProps = (state) => {
            return ({
                loading: state.auth.loading,
                error: state.auth.error,
            });
        }
        const mapDispatchToProps = {
            ResetPassword
           }
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Resetpassword));
