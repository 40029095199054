import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Form, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import 'react-phone-number-input/style.css'
import PhoneInput, { getCountries, getCountryCallingCode,isPossiblePhoneNumber } from 'react-phone-number-input/input'

const ContactInfo = (props) => {

    const [country, setCountry] = useState('IN');
    function checkEmail(mail) 
    {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
    {
        return {message:"",valid:true}
    }
    return {message:'Please enter a valid email address',valid:false};
    
    }
    function checkmobileNumber(number) {
        let isd = props.details.isd_code || "IN"
        console.log(isPossiblePhoneNumber("+"+getCountryCallingCode(isd)+number));
        if(number==="") {
            return ({
                message:"Phone number should not be blank",
                valid:false,
            });
        }

        else if(isPossiblePhoneNumber("+"+getCountryCallingCode(isd)+number))  {
            return ({
                message:"",
                valid:true,
            });
        }

        else {
            return ({
                message:"Please enter a valid phone number",
                valid:false,
            });
        }
    
}
    return (
        <TabContent activeTab={props.activeTab} className="twitter-bs-wizard-tab-content">
            <TabPane tabId={3} className="employment-details text-left">
                <Form>
                    <Row>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Email</Label>
                                <Input type="email" value={props.details.recruiter_email || ''} onChange={(event) => props.OnchangeCallBack(event.target.value, 'email')} />
                              {props.details.recruiter_email!==undefined &&  <label style={{"margin-bottom":"0","color":"red"}}>{checkEmail(props.details.recruiter_email).message}</label>}
            
                                <div className='font-size-12 font-weight-light mt-2'>
                                    (please provide head recruiter’s email address)
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup className="text-left">
                                <Label for="basicpill-lastname-input2">Phone Number </Label>
                                <Row>
                                    <Col lg="4" xs='4' className="pr-0">
                                        <select
                                            className="form-control phone-number-select"
                                            value={props.details.isd_code || "IN"}
                                            onChange={event => { props.OnchangeCallBack(event.target.value, 'isd_code'); props.details.number = '' }}>
                                            {getCountries().map((country) => (
                                                <option key={country} value={country}>
                                                    {country} {getCountryCallingCode(country)}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col lg="8" xs='8'>
                                        {/* <PhoneInput
                                            country={country ? country : 'IN'}
                                            international
                                            value={props.details.number || ''}
                                            onChange={(value)=> props.OnchangeCallBack(value, 'mobile')} 
                                            className="form-control phone-number-input"
                                        /> */}
                                        <Input type="tel" maxLength={13}
                                            value={props.details.recruiter_phone || ''}
                                            onChange={(e) => props.OnchangeCallBack(e.target.value, 'mobile')}
                                        />
                                       
                                    </Col>
                                    {props.details.recruiter_phone!==undefined &&  <label style={{"margin-bottom":"0","color":"red"}}>{checkmobileNumber(props.details.recruiter_phone).message}</label>}
            
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </TabPane>
        </TabContent>
    )
}

export default ContactInfo;