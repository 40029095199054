import api from '../../config/axios';
import { CHANGE_PASSWORD , CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_LOADING } from '../accountsetting';
import toastr from "toastr";
import "toastr/build/toastr.min.css";


export const changePassword = (currentPassword, password, confirmPassword) => async dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_LOADING,
    });
    try {
        const response = await api.put('/api/account_settings/change_password', { currentPassword, password, confirmPassword}, 
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                }
            });

        if (response.status === 200) {
            if(response.data.success){
                toastr.success("Password updated successfully!", "", { timeOut: 2000 });
                dispatch({
                    type: CHANGE_PASSWORD,
                    payload: response.data,
                });
            }else{
                toastr.error(response.data || "Something went wrong", "", { timeOut: 2000 });

                dispatch({
                    type: CHANGE_PASSWORD_ERROR,
                    payload: response.data
                });     
            }
        }
        else {
            toastr.error(response.data || "Something went wrong", "", { timeOut: 2000 });

            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                payload: response.data
            });        
        }
    }
    catch (err) {
        toastr.error(err.response.data.message || "Something went wrong", "", { timeOut: 2000 });

        dispatch({
            type: CHANGE_PASSWORD_ERROR,
            payload: err.response.data
        });
    }

}