import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
} from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Loader from '../../components/loader/loader';

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
//Redux actions
import {
  signupWithEmail,
  signupWithGoogle,
  newPage,
} from "../../redux/actions/auth";
import { GoogleLoginButton } from "../../components/AuthButton/Google";
import { LinkedInLoginButton } from "../../components/AuthButton/LinkedIn";

import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";
import logo from "../../assets/images/Zunavish_text.svg";
import Common from "../../helpers/common";

const SignUp = (props) => {
  useEffect(() => props.newPage(), []);

  const [email, setEmail] = useState("");


  //handleValidSubmit
  function handleValidSubmit(event, values) {
    props.signupWithEmail(email, props.history);
    console.log(props.loading);
  }

  function responseGoogle(response) {
    const tokenid = response.tokenObj.id_token;
    props.signupWithGoogle(tokenid, props.history);
  }

  return (
    <React.Fragment>
      {
        props.loading ? <Loader/> :
        (
          <>
          <div className="account-pages">
          <div className="float-left left-section">
            <Row className="">
              <Col md={12} lg={12}>
                <div className="pl-3 pr-4 pb-2 login-heading">
                  <h5 className="font-size-36 font-weight-bold green-text-color ">
                    Join <img src={logo} alt="" height="35" className="pb-2" />
                  </h5>
                  <div className="text-left signup-description custom-signup-description font-size-16">
                    Create an account to receive great stories in your inbox,
                    personalize your homepage, and follow authors and topics that
                    you love.
                  </div>
                </div>
  
                <div className="pl-4">
                  <AvForm
                    className="form-horizontal login-form"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {props.error && props.error ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}
                    {/* <AvField name="email" label="Email" className="form-control" placeholder="Eg:john@gmail.com"
                                          type="email" required onChange={({ target }) => { setEmail(target.value); }} /> */}
                    <AvGroup>
                      <Label for="email" className="font-dark-green">
                        Email
                      </Label>
                      <InputGroup>
                        <AvInput
                          name="email"
                          type="email"
                          placeholder="Eg: john@gmail.com"
                          required
                          onChange={({ target }) => {
                            target.value = Common.trimStart(target.value);
                            setEmail(target.value);
                          }}
                        />
  
                        <AvFeedback>{email===""?"Email cannot be blank.":"Invalid email."}</AvFeedback>
                      </InputGroup>
                    </AvGroup>
                    <div className="mt-3 text-left pb-3 border-bottom">
                      <button
                        className="btn bg-green waves-effect waves-light w-lg mr-2"
                        type="submit"
                      >
                        Continue
                      </button>
                      {/* <span
                        className="mr-2"
                        style={{ color: " #97A4B0", fontSize: "12px" }}
                      >
                        Or
                      </span>
                      <GoogleLoginButton onSuccess={responseGoogle} />
                      <LinkedInLoginButton
                        onSuccess={() => {}}
                        onFailure={() => {}}
                      /> */}
                    </div>
  
                    <div
                      className="mt-2 "
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        fontWeight: "300",
                        color: "#174731",
                      }}
                    >
                      Already have an account?{" "}
                      <Link
                        to="/login"
                        className="font-weight-semibold green-text-color sign-in-link"
                      >
                        Sign In
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </div>
          <div className="float-left right-section">
            <div className="text-container">
              <div className="company-text-bold">
                <img src={text_logo} alt="text" />
              </div>
              <div className="mt-2 text-center">
                {/* vivify your business and People */}
              </div>
            </div>
          </div>
        </div>
        </>
        )
      }
      
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};
const mapDispatchToProps = {
  signupWithEmail,
  signupWithGoogle,
  newPage,
};

// export default withRouter(SignUp);
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SignUp));
