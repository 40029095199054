import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, FormGroup, Label, Input, InputGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { Domains, CollegeDegrees } from '../../../helpers/constant_variables.mjs';

// const DegreeDropDownData = [
//     {
//       "value": 1,
//       "label": "Graduation/Diploma",
//       "courses":[
//         {
//           value:1,
//           gid:1,
//           label:'B.Tech/B.E.'
//         },
//         {
//             value:2,
//           gid:1,
//           label:'B.Sc'
//         },
//           {
//           value:3,
//           gid:1,
//           label:'B.A'
//         }
//       ]
//     },
//     {
//       "value": 2,
//       "label": "Masters/Post-Graduation",
//         "courses":[
//         {
//           value:1,
//           gid:2,
//           label:'MS/M.Sc(Science)'
//         },
//         {
//           value:2,
//           gid:2,
//           label:'MBA/PGDM'
//         },
//           {
//           value:3,
//           gid:2,
//           label:'M.A'
//         }
//       ]
//     },
//     {
//       "value": 3,
//       "label": "Doctorate/PhD",
//        "courses":[
//         {
//           value:1,
//           gid:3,
//           label:'Ph.D/Doctorate'
//         },
//         {
//           value:2,
//           gid:3,
//           label:'MPHIL'
//         }
//       ]
//     }
// ]

const DegreeDetailModal = (props) => {

    const [college, setCollege] = useState(props.educationEditedData ? props.educationEditedData.total_students : undefined);
    const [degree, setDegree] = useState(props.educationEditedData ? props.educationEditedData.degree : undefined);
    const [course, setCourse] = useState(props.educationEditedData ? props.educationEditedData.course : undefined);
    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [stillStudying, setStillStudying] = useState(false);
    const [courseData, setCourseData] = useState([]);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        setCourseData(props.educationEditedData)
    }, [props.educationEditedData])

    useEffect(() => {
        setCourseData(props.educationEditedData);
        setCourses(CollegeDegrees[0].courses)
    }, []);

    const handleCourseChange = selectedOption => {
        setCourse(selectedOption);
    };

    const handleChange = (value, type) => {
        switch (type) {
            case 'degree':
                setDegree(value.label);
                setCourses(value.courses);
                setCourseData({
                    ...courseData,
                    degree: value.label,
                    course: [],
                    couseObjArray: [],
                })
                break;
            case 'course':
                try {
                    console.log(value)
                    if (value) {
                        setCourseData({
                            ...courseData,
                            course: value.map((e) => e.label),
                            couseObjArray: [...value]
                        });
                    } else {
                        setCourseData({
                            ...courseData,
                            course: [],
                            couseObjArray: []
                        });
                    }
                    break;
                }
                catch (e) {
                    setCourse([]);
                    setCourseData({ ...courseData, course: [] });
                }
            case 'total_students':
                setCollege(value);
                setCourseData({
                    ...courseData,
                    total_students: value,
                });

            default:
                break;
        }
    };

    function clearData() {
        setCollege();
        setDegree({});
        setCourse({});
        setCourseData([]);
    }

    return (
        <Modal
            isOpen={props.modal_center}
            centered={props.centered}
            className='add-education'
            size="lg"
        >
            <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                    {props.educationEditedData ? "Edit" : "Add"} Degree Details
                </h6>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label>Degree</Label>
                            <Select
                                className="select-container"
                                value={{ value: 1, label: courseData ? courseData.degree : "" }}
                                onChange={(selectedOption) => {
                                    handleChange(selectedOption, 'degree')
                                }}
                                options={CollegeDegrees}
                                isSearchable
                                innerProps={{ className: "test" }}
                                placeholder="Select"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            {console.log('obj', courseData && courseData.couseObjArray)}
                            <Label for="basicpill-colg-input1">Course</Label>
                            <Select
                                className="select-container multiple-select"
                                value={courseData && courseData.couseObjArray}
                                onChange={(selectedOption) => handleChange(selectedOption, 'course')}
                                options={courses}
                                isSearchable
                                innerProps={{ className: "string" }}
                                placeholder="Select"
                                isMulti={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row >
                    <Col lg="6">
                        <FormGroup>
                            <Label>Total students registered for the degree</Label>
                            <Input type="number" value={courseData ? courseData.total_students : ''} onChange={(e) => handleChange(e.target.value, 'total_students')} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-green waves-effect"
                    data-dismiss="modal"
                >
                    Close
                </button>
                {props.educationEditedData ?
                    <button
                        type="button"
                        className="btn bg-green waves-effect waves-light"
                        onClick={() => { props.onModalSubmit({ total_students: college, degree: degree, course: courseData.course, sDate, eDate, stillStudying, id: props.educationEditedData.id }, "edit"); }}
                    >Save </button>
                    :
                    <button
                        type="button"
                        className="btn bg-green waves-effect waves-light"
                        disabled={!degree || !courseData.course || (courseData.course && courseData.course.length === 0) || !courseData.total_students ? true : false}
                        onClick={() => { props.onModalSubmit({ degree: degree, course: courseData.course, total_students: college }, "save"); clearData(); }}
                    >Create </button>
                }
            </div>
        </Modal>
    );
}

export default DegreeDetailModal;