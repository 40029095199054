import axios from "axios";
const API_END_POINT = "https://api.zunavish.com/api";
// const API_END_POINT = "http://localhost:4192/api";

const headers = {
  "Content-Type": "application/json",
  headers: { "Access-Control-Allow-Origin": "*" },
};

export default class RestApi {
  static addCompanyDetails(data) {
    return axios.post(API_END_POINT + "/organizations/add", data, headers);
  }

  static addJobPost(data) {
    return axios.post(API_END_POINT + "/jobs/add", data, headers);
  }
  static getPublicProfileData(id) {
    return axios.get(API_END_POINT + "/users/user/public", { params: { id } });
  }

  static deleteJobPost(data) {
    // return axios.delete(API_END_POINT+'/jobs/delete',data, headers);
    return fetch(API_END_POINT + "/jobs/delete", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getJobListByStatus(status) {
    return axios.get(API_END_POINT + `/jobs/list?status=${status}`, headers);
  }

  static addJobPost(data) {
    return axios.post(API_END_POINT + "/jobs/add", data, headers);
  }

  static deleteJobPost(data) {
    // return axios.delete(API_END_POINT+'/jobs/delete',data, headers);
    return fetch(API_END_POINT + "/jobs/delete", {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static getJobListByStatus(status) {
    return axios.get(API_END_POINT + `/jobs/list?status=${status}`, headers);
  }

  static getJobDetails(id) {
    return axios.get(API_END_POINT + `/jobs/client?id=${id}`, headers);
  }

  static updateJobStatus(data) {
    return axios.put(API_END_POINT + `/jobs/update`, data, headers);
  }

  static getJobApplicationByStatus(id, status) {
    return axios.get(
      API_END_POINT + `/jobs/applications?id=${id}&status=${status}`,
      headers
    );
  }

  static updateJobApplicationStatus(data) {
    return axios.put(API_END_POINT + `/jobs/change_status`, data, headers);
  }

  static getProfileDetails() {
    return axios.get(API_END_POINT + `/organizations/`, headers);
  }

  static addCollege(data) {
    return axios.post(API_END_POINT + "/college/add", data, headers);
  }

  static updateCollege(data) {
    return axios.put(API_END_POINT + "/college/update", data, headers);
  }

  static addCollegeCourse(data) {
    return axios.post(API_END_POINT + "/college/course/add", data, headers);
  }
  static updateCollegeCourse(data) {
    return axios.put(API_END_POINT + "/college/course/update", data, headers);
  }

  static getStudents(page, active) {
    return axios.get(
      API_END_POINT + `/college/students?page=${page}&is_active=${active}`,
      headers
    );
    // return axios.get(
    //   API_END_POINT +
    //     `/college/studentsSummary?offset=${page}&is_active=${active}`,
    //   headers
    // );
  }
  static test(page, active) {
    return axios.get(API_END_POINT + `/college/students`, headers);
  }
  static getStudentsLength(page, active) {
    return axios.get(
      API_END_POINT +
        `/college/studentsSummary?offset=${page}&is_active=${active}`,
      headers
    );
  }

  static getProfile() {
    return axios.get(API_END_POINT + `/college/profile`, headers);
  }

  static invite(data) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    return axios.post(API_END_POINT + "/college/invite", data, headers);
  }

  static uploadLogo(data) {
    return axios.post(API_END_POINT + "/college/upload/logo", data, headers);
  }

  static saveEducationDetailsOnBoarding(data) {
    return axios.post(API_END_POINT + "/onboarding/educations", data, headers);
  }

  static editEducationDetailsOnBoarding(data) {
    return axios.put(API_END_POINT + "/profile/edit/educations", data, headers);
  }

  static getProfileData() {
    return axios.get(API_END_POINT + "/users/user", headers);
  }

  static saveOnboardingPersonalDetails(data) {
    return axios.put(
      API_END_POINT + "/onboarding/personal_details",
      data,
      headers
    );
  }

  static deleteEducationDetailsOnBoarding(data, callback) {
    // return axios.delete(API_END_POINT+'/profile/delete/educations', {headers,data});
    let header = {
      token: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    return fetch(API_END_POINT + "/profile/delete/educations", {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static importcsv(data) {
    return axios.post(API_END_POINT + "/college/importcsv", data, headers);
  }
  static getStudentsList(value) {
    return axios.get(API_END_POINT + `/college/search?query=${value}`, headers);
  }

  static getS3UploadURL(filetype, ext, id) {
    const headr = {
      headers: {
        token: `${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (id) {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,
          fileId: id,
          fileExtension: ext,
        },
        headr
      );
    } else {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,

          fileExtension: ext,
        },
        headr
      );
    }
  }
  static uploadToS3(url, file) {
    return axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  }
}
