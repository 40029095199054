import React, {useState, useEffect} from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allroutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import Loader from './components/loader/LoadManager';

// Import scss
import "./assets/scss/theme.scss";

const App = (props) => { 

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		removeListners();
	}, []);

	function removeListners(){
		return () => {
		  window.removeEventListener('resize', handleResize);
		}
	}
	
	function handleResize(){
		if (window.innerWidth <= 768) {
		  document.body.classList.add('mobileDevice');
		  setIsMobile(true);
		} else {
		  document.body.classList.remove('mobileDevice');
		  setIsMobile(false);
		}
	}
  
  
  function getLayout() {
    let layoutCls = VerticalLayout;
		switch (props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

  	const Layout = getLayout();

	const NonAuthmiddleware = ({
			component: Component,
			layout: Layout
		}) => (
				<Route
					render={props => {
						return (
						<Layout>
							<Component {...props}  />
						</Layout>
						);
					}}
				/>
		);


  return(
    <React.Fragment>
				<Router>
					<Switch>
						{authRoutes.map((route, idx) => (
							<NonAuthmiddleware
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
							/>
						))}
						{userRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isMobile={isMobile}
							/>
						))}
					</Switch>
				</Router>
				<Loader/>
		</React.Fragment>
  )
}


const mapStateToProps = state => {
	return {
		layout: state.layout
	};
};

export default connect(mapStateToProps, null)(App);
