import ApiService from "../config/restapi";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default class s3apis {
  static upload = async (filetype, ext, file, id) => {
    try {
      const response = await ApiService.getS3UploadURL(filetype, ext, id);
      console.log(response);
      const s3url = response.data.url;
      const fileResponse = await ApiService.uploadToS3(s3url, file);
      toastr.success("File was successfully uploaded!", "", {
        timeOut: 2000,
      });
      console.log("File was successfully uploaded!");
      return `${s3url.split("?").shift()}?${new Date().getTime()}`;
    } catch (e) {
      toastr.error(e.message, "", {
        timeOut: 2000,
      });
      console.log("An error occurred!", e.message);
    }
  };
}
