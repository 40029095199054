import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import CompanyDetails from "./CollegeDetails";
import AboutCompany from "./DegreeCourse";
import ContactInfo from "./ContactInfo";
import { withRouter } from "react-router-dom";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Common from "../../../helpers/common";

const CompanyWizard = (props) => {
  const [companyDetailObj, setCompanyDetailObj] = useState({});

  const wizardHeadersNames = [
    { name: "College Details", key: "pd-width" },
    { name: "Degrees and Courses", key: "ed-width" },
    { name: "Contact Information", key: "empd-width" },
  ];

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);
  const [details, setdetails] = useState({});
  const [degrees, setdegrees] = useState([]);
  const OnchangeCallBack = (value, type) => {
    switch (type) {
      case "designation":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));

        setCompanyDetailObj({
          ...companyDetailObj,
          designation: value,
        });
        break;
      case "name":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));

        setCompanyDetailObj({
          ...companyDetailObj,
          name: value,
        });
        break;
      case "company_logo":
        setCompanyDetailObj({
          ...companyDetailObj,
          logo_url: value,
        });
        break;
      case "location":
        setCompanyDetailObj({
          ...companyDetailObj,
          location: value,
        });
        break;
      case "websiteurl":
        value = Common.trimStart(value);

        setCompanyDetailObj({
          ...companyDetailObj,
          website_url: value,
        });
        break;

      case "total_students":
        // setCompanyDetailObj({
        //     ...companyDetailObj,
        //     total_students: value
        // })
        setdetails({ ...details, total_students: value });
        break;
      case "degree":
        setdetails({
          ...details,
          degree: value,
        });
        break;
      case "courses_tag":
        const tempData = [...value];
        const updatedMustSkills = tempData.map((item) => {
          return item.label;
        });
        tempData["courses"] = [...updatedMustSkills];
        setdetails({ ...details, course: [...tempData.courses] });
        break;
      case "extra":
        setdegrees(value);
        break;
      case "email":
        value = Common.trimStart(value);
        setCompanyDetailObj({
          ...companyDetailObj,
          recruiter_email: value,
        });
        break;
      case "mobile":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));

        setCompanyDetailObj({
          ...companyDetailObj,
          recruiter_phone: value,
        });
        break;
      case "isd_code":
        console.log(value);
        setCompanyDetailObj({
          ...companyDetailObj,
          isd_code: value,
        });
        break;

      default:
        break;
    }
  };

  const onNextClickEduDataCallBack = (eduData, rowData, editIndex) => {};

  const onNextClickEmploymentCallBack = (empData, rowData, editIndex) => {};

  const onSkipClick = (tab) => {
    if (tab === 1) {
      if (
        !companyDetailObj.designation ||
        !companyDetailObj.name ||
        !companyDetailObj.location ||
        companyDetailObj.location === "Select"
      ) {
        let tempobj = {};
        if (companyDetailObj.designation === undefined) {
          tempobj.designation = "";
        }

        if (companyDetailObj.name === undefined) {
          tempobj.name = "";
        }

        if (companyDetailObj.location === undefined) {
          tempobj.location = "";
        }

        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      setactiveTab(2);
    } else if (tab === 2) {
      setactiveTab(3);
    } else if (tab === 3) {
      props.onCompleteAboutYourselfCallBack();
    }
  };

  const onNextClick = (tab) => {
    if (tab === 1) {
      if (
        !companyDetailObj.designation ||
        !companyDetailObj.name ||
        !companyDetailObj.location ||
        companyDetailObj.location === "Select"
      ) {
        let tempobj = {};
        if (companyDetailObj.designation === undefined) {
          tempobj.designation = "";
        }

        if (companyDetailObj.name === undefined) {
          tempobj.name = "";
        }

        if (companyDetailObj.location === undefined) {
          tempobj.location = "";
        }

        setCompanyDetailObj({
          ...companyDetailObj,
          ...tempobj,
        });
        return;
      }
      if (companyDetailObj.website_url) {
        const regexp =
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (!regexp.test(companyDetailObj.website_url)) {
          toastr.error("Please enter valid website URL.", "", {
            timeOut: 2000,
          });
        } else {
          setactiveTab(2);
        }
      } else {
        setactiveTab(2);
      }
    } else if (tab === 2) {
      setactiveTab(3);
    } else if (tab === 3) {
      let collegeDetails = {
        designation: companyDetailObj.designation,
        name: companyDetailObj.name,
        logo_url: companyDetailObj.logo_url,
        location: companyDetailObj.location,
        website_url: companyDetailObj.website_url,
        recruiter_email: companyDetailObj.recruiter_email,
        recruiter_phone: companyDetailObj.recruiter_phone,
      };
      console.log(degrees);
      let degreedata = [...degrees];
      console.log(degreedata);
      
      ApiService.addCollege(collegeDetails).then((res) => {
        if (res && res.status === 200 && res.data.success) {
          let degreeDetails = {
            degreedata,

            college_id: res.data.college.id,
          };
          console.log(res.data.college.id)
          ApiService.addCollegeCourse(degreeDetails).then((res) => {
            if (res && res.status === 200 && res.data.success) {
              
              localStorage.removeItem("onboarding");
              localStorage.setItem("onboarding", "true");
              props.history.push("/collegeDashboard");
            } else {
              toastr.error("Something Went Wrong", "", { timeOut: 2000 });
            }
          });
        } else {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        }
      });
    }
  };

  // useEffect(() => {
  //     ApiService.getProfileDetails().then((res) => {
  //         if(res && res.status === 200 && res.data){
  //             setCompanyDetailObj({
  //                 ...companyDetailObj,
  //                 name : res.data.name
  //             });
  //         }else{
  //             toastr.error("Something Went Wrong", "", { timeOut: 2000 });
  //         }
  //     });

  // }, []);

  useEffect(() => {
    wizardHeadersNames.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = wizardHeadersNames[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2 || activeTab === 3) {
        if (activeTab === 3)
          wizardHeadersNames[activeTab - 3]["makeactive"] = activeTab - 3;
        wizardHeadersNames[activeTab - 2]["makeactive"] = activeTab - 2;
        wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...wizardHeadersNames]);
  }, [activeTab]);

  const ToggleHeaderTab = (tab, item) => {
    if ("makeactive" in item) {
      setactiveTab(item.makeactive + 1);
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            onClick={() => {
              ToggleHeaderTab(activeTab, item1);
            }}
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-12 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div id="basic-pills-wizard" className="twitter-bs-wizard company-wizard">
      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
        {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
      </ul>
      <Card>
        <CardBody>
          {activeTab === 1 ? (
            <CompanyDetails
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              details={companyDetailObj}
            />
          ) : activeTab === 2 ? (
            <AboutCompany
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              onNextClickEduDataCallBack={onNextClickEduDataCallBack}
              details={companyDetailObj}
              degrees={degrees}
            />
          ) : (
            <ContactInfo
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              details={companyDetailObj}
              onNextClickEmploymentCallBack={onNextClickEmploymentCallBack}
            />
          )}
        </CardBody>
      </Card>
      <div className="text-right">
        {activeTab !== 3 && (
          <button
            className={classnames(
              "btn btn-outline-grey waves-effect waves-light w-lg mr-2"
            )}
            type="submit"
            onClick={() => onSkipClick(activeTab)}
          >
            Skip
          </button>
        )}
        <button
          className="btn bg-green waves-effect waves-light w-lg"
          type="submit"
          onClick={() => onNextClick(activeTab)}
          disabled={
            activeTab === 3 &&
            (!companyDetailObj.recruiter_email ||
              !companyDetailObj.recruiter_phone)
              ? true
              : false
          }
        >
          {activeTab === 3 ? "Start Exploring" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(CompanyWizard);
