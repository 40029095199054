import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import default_company_logo from "../../../assets/images/d-company-logo.png";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import cloudOutline from "../../../assets/images/cloud-outline.png";
import ApiService from "../../../config/restapi";
import s3api from "../../../helpers/s3apis";
import Select from "react-select";
import { Domains } from "../../../helpers/constant_variables.mjs";
import Common from "../../../helpers/common";

var mailformat =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var telformart = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
var urlformat =
  /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
const CompnaySizes = [
  {
    value: 1,
    label: "0-10 employees",
    modifyName: "Start up/ Small Enterprise",
  },
  {
    value: 2,
    label: "10-50 employees",
    modifyName: "Mid sized",
  },
  {
    value: 3,
    label: "50-200+ employees",
    modifyName: "Large scale/ Global",
  },
];

const EditProfileModal = (props) => {
  const [country, setCountry] = useState("IN");
  const [fname, setFName] = useState();
  const [industry, setIndustry] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [size, setSize] = useState();
  const [location, setLocation] = useState();
  const [url, setUrl] = useState();
  const [profilepic, setProfilepic] = useState(null);
  const [companySizes, setCompanySizes] = useState(CompnaySizes);
  const [isdCode, setIsdCode] = useState();
  const [showError, setShowError] = useState(false);

  const [editData, setEditData] = useState();
  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDistrict, setSelectedDistrict] = useState("Select");
  const countries = [
    { name: "Select", districts: ["Select"] },
    {
      name: "India",
      districts: [
        "Select",
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Hyderabad",
        "Ahmedabad",
        "Chennai",
        "Kolkata",
        "Surat",
        "Pune",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Indore",
        "Thane",
        "Bhopal",
        "Visakhapatnam",
        "Pimpri-Chinchwad",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivali",
        "Vasai-Vira",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "NaviMumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
      ],
    },
    {
      name: "US",
      districts: [
        "Select",
        "Aberdeen",
        "Abilene",
        "Akron",
        "Albany",
        "Albuquerque",
        "Alexandria",
        "Allentown",
        "Amarillo",
        "Anaheim",
        "Anchorage",
        "Ann Arbor",
        "Antioch",
        "Apple Valley",
        "Appleton",
        "Arlington",
        "Arvada",
        "Asheville",
        "Athens",
        "Atlanta",
        "Atlantic City",
        "Augusta",
        "Aurora",
        "Austin",
        "Bakersfield",
        "Baltimore",
        "Barnstable",
        "Baton Rouge",
        "Beaumont",
        "Bel Air",
        "Bellevue",
        "Berkeley",
        "Bethlehem",
        "Billings",
        "Birmingham",
        "Bloomington",
        "Boise",
        "Boise City",
        "Bonita Springs",
        "Boston",
        "Boulder",
        "Bradenton",
        "Bremerton",
        "Bridgeport",
        "Brighton",
        "Brownsville",
        "Bryan",
        "Buffalo",
        "Burbank",
        "Burlington",
        "Cambridge",
        "Canton",
        "Cape Coral",
        "Carrollton",
        "Cary",
        "Cathedral City",
        "Cedar Rapids",
        "Champaign",
        "Chandler",
        "Charleston",
        "Charlotte",
        "Chattanooga",
        "Chesapeake",
        "Chicago",
        "Chula Vista",
        "Cincinnati",
        "Clarke County",
        "Clarksville",
        "Clearwater",
        "Cleveland",
        "College Station",
        "Colorado Springs",
        "Columbia",
        "Columbus",
        "Concord",
        "Coral Springs",
        "Corona",
        "Corpus Christi",
        "Costa Mesa",
        "Dallas",
        "Daly City",
        "Danbury",
        "Davenport",
        "Davidson County",
        "Dayton",
        "Daytona Beach",
        "Deltona",
        "Denton",
        "Denver",
        "Des Moines",
        "Detroit",
        "Downey",
        "Duluth",
        "Durham",
        "El Monte",
        "El Paso",
        "Elizabeth",
        "Elk Grove",
        "Elkhart",
        "Erie",
        "Escondido",
        "Eugene",
        "Evansville",
        "Fairfield",
        "Fargo",
        "Fayetteville",
        "Fitchburg",
        "Flint",
        "Fontana",
        "Fort Collins",
        "Fort Lauderdale",
        "Fort Smith",
        "Fort Walton Beach",
        "Fort Wayne",
        "Fort Worth",
        "Frederick",
        "Fremont",
        "Fresno",
        "Fullerton",
        "Gainesville",
        "Garden Grove",
        "Garland",
        "Gastonia",
        "Gilbert",
        "Glendale",
        "Grand Prairie",
        "Grand Rapids",
        "Grayslake",
        "Green Bay",
        "GreenBay",
        "Greensboro",
        "Greenville",
        "Gulfport-Biloxi",
        "Hagerstown",
        "Hampton",
        "Harlingen",
        "Harrisburg",
        "Hartford",
        "Havre de Grace",
        "Hayward",
        "Hemet",
        "Henderson",
        "Hesperia",
        "Hialeah",
        "Hickory",
        "High Point",
        "Hollywood",
        "Honolulu",
        "Houma",
        "Houston",
        "Howell",
        "Huntington",
        "Huntington Beach",
        "Huntsville",
        "Independence",
        "Indianapolis",
        "Inglewood",
        "Irvine",
        "Irving",
        "Jackson",
        "Jacksonville",
        "Jefferson",
        "Jersey City",
        "Johnson City",
        "Joliet",
        "Kailua",
        "Kalamazoo",
        "Kaneohe",
        "Kansas City",
        "Kennewick",
        "Kenosha",
        "Killeen",
        "Kissimmee",
        "Knoxville",
        "Lacey",
        "Lafayette",
        "Lake Charles",
        "Lakeland",
        "Lakewood",
        "Lancaster",
        "Lansing",
        "Laredo",
        "Las Cruces",
        "Las Vegas",
        "Layton",
        "Leominster",
        "Lewisville",
        "Lexington",
        "Lincoln",
        "Little Rock",
        "Long Beach",
        "Lorain",
        "Los Angeles",
        "Louisville",
        "Lowell",
        "Lubbock",
        "Macon",
        "Madison",
        "Manchester",
        "Marina",
        "Marysville",
        "McAllen",
        "McHenry",
        "Medford",
        "Melbourne",
        "Memphis",
        "Merced",
        "Mesa",
        "Mesquite",
        "Miami",
        "Milwaukee",
        "Minneapolis",
        "Miramar",
        "Mission Viejo",
        "Mobile",
        "Modesto",
        "Monroe",
        "Monterey",
        "Montgomery",
        "Moreno Valley",
        "Murfreesboro",
        "Murrieta",
        "Muskegon",
        "Myrtle Beach",
        "Naperville",
        "Naples",
        "Nashua",
        "Nashville",
        "New Bedford",
        "New Haven",
        "New London",
        "New Orleans",
        "New York",
        "New York City",
        "Newark",
        "Newburgh",
        "Newport News",
        "Norfolk",
        "Normal",
        "Norman",
        "North Charleston",
        "North Las Vegas",
        "North Port",
        "Norwalk",
        "Norwich",
        "Oakland",
        "Ocala",
        "Oceanside",
        "Odessa",
        "Ogden",
        "Oklahoma City",
        "Olathe",
        "Olympia",
        "Omaha",
        "Ontario",
        "Orange",
        "Orem",
        "Orlando",
        "Overland Park",
        "Oxnard",
        "Palm Bay",
        "Palm Springs",
        "Palmdale",
        "Panama City",
        "Pasadena",
        "Paterson",
        "Pembroke Pines",
        "Pensacola",
        "Peoria",
        "Philadelphia",
        "Phoenix",
        "Pittsburgh",
        "Plano",
        "Pomona",
        "Pompano Beach",
        "Port Arthur",
        "Port Orange",
        "Port Saint Lucie",
        "Port St. Lucie",
        "Portland",
        "Portsmouth",
        "Poughkeepsie",
        "Providence",
        "Provo",
        "Pueblo",
        "Punta Gorda",
        "Racine",
        "Raleigh",
        "Rancho Cucamonga",
        "Reading",
        "Redding",
        "Reno",
        "Richland",
        "Richmond",
        "Richmond County",
        "Riverside",
        "Roanoke",
        "Rochester",
        "Rockford",
        "Roseville",
        "Round Lake Beach",
        "Sacramento",
        "Saginaw",
        "Saint Louis",
        "Saint Paul",
        "Saint Petersburg",
        "Salem",
        "Salinas",
        "Salt Lake City",
        "San Antonio",
        "San Bernardino",
        "San Buenaventura",
        "San Diego",
        "San Francisco",
        "San Jose",
        "Santa Ana",
        "Santa Barbara",
        "Santa Clara",
        "Santa Clarita",
        "Santa Cruz",
        "Santa Maria",
        "Santa Rosa",
        "Sarasota",
        "Savannah",
        "Scottsdale",
        "Scranton",
        "Seaside",
        "Seattle",
        "Sebastian",
        "Shreveport",
        "Simi Valley",
        "Sioux City",
        "Sioux Falls",
        "South Bend",
        "South Lyon",
        "Spartanburg",
        "Spokane",
        "Springdale",
        "Springfield",
        "St. Louis",
        "St. Paul",
        "St. Petersburg",
        "Stamford",
        "Sterling Heights",
        "Stockton",
        "Sunnyvale",
        "Syracuse",
        "Tacoma",
        "Tallahassee",
        "Tampa",
        "Temecula",
        "Tempe",
        "Thornton",
        "Thousand Oaks",
        "Toledo",
        "Topeka",
        "Torrance",
        "Trenton",
        "Tucson",
        "Tulsa",
        "Tuscaloosa",
        "Tyler",
        "Utica",
        "Vallejo",
        "Vancouver",
        "Vero Beach",
        "Victorville",
        "Virginia Beach",
        "Visalia",
        "Waco",
        "Warren",
        "Washington",
        "Waterbury",
        "Waterloo",
        "West Covina",
        "West Valley City",
        "Westminster",
        "Wichita",
        "Wilmington",
        "Winston",
        "Winter Haven",
        "Worcester",
        "Yakima",
        "Yonkers",
        "York",
        "Youngstown",
      ],
    },
  ];

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "jpeg" || ext === "png" || ext === "gif" || ext === "jpg") {
      return true;
    }
    return false;
  }

  const handleAcceptedFiles = async (files) => {
    if (validateFileExtention(files)) {
      console.log(files[0].name);

      var ext = files[0].name.split(".").pop();
      if (files[0].size < 5242880) {
        const url = await s3api.upload("logo", ext, files[0]);
        OnchangeHandler(url, "logo_url");
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }
      // const formData = new FormData();
      // formData.append("file", files[0]);

      // ApiService.uploadLogo(formData).then((res) => {
      //     if(res && res.status === 200 && res.data){
      //       setProfilepic(res.data.link);
      //       setEditData({
      //         ...editData,
      //         logo_url:res.data.link
      //     })
      //     }
      // });
    } else {
      toastr.error("Please Upload Valid Photo", "", { timeOut: 2000 });
    }
  };

  function clearData() {}

  const removeProfilePic = () => {
    setProfilepic(null);
  };

  const OnchangeHandler = (value, type) => {
    switch (type) {
      case "recruiter_email":
        value = Common.trimStart(value);

        setEditData({
          ...editData,
          recruiter_email: value,
        });
        break;
      case "recruiter_phone":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setEditData({
          ...editData,
          recruiter_phone: value,
        });
        break;
      case "location":
        setEditData({
          ...editData,
          location: value,
        });
        break;
      case "website_url":
        value = Common.trimStart(value);
        setEditData({
          ...editData,
          website_url: value,
        });
        break;
      case "isd_code":
        setIsdCode(value);
        break;

      case "logo_url":
        setProfilepic(value);
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("hey");
    console.log(props.editedData);
    setEditData(props.editedData);
    if (props.editedData) {
      setFName(props.editedData.name);
      // setEmail(props.editedData.recruiter_email);
      // setMobileNumber(props.editedData.recruiter_phone);
      setProfilepic(props.editedData.logo_url);
      setIsdCode(props.editedData.isd_code);

      // setSize(props.editedData.size);
      // setLocation(props.editedData.location);
      // setUrl(props.editedData.website_url);
      // setIndustry({ label : props.editedData.industry});
      // makeCompanySizeSelected(props.editedData.size);
    }
  }, [props.editedData]);

  function makeCompanySizeSelected(size) {
    let tempSizes = [...companySizes];
    tempSizes.map((item) => {
      if (item.label === size) {
        item["selected"] = "selected";
      }
    });
  }

  function companySizeSelect(item, index) {
    let tempData = [...companySizes];
    tempData.map((item, idx) => {
      if (index === idx) {
        item["selected"] = "selected";
        setSize(item.label);
      } else {
        delete item.selected;
      }
    });
    setCompanySizes([...tempData]);
  }

  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }
  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  const savechanges = () => {
    editData.isd_code = isdCode;
    editData.logo_url = profilepic;
    if (editData.recruiter_email.match(mailformat)) {
      if (editData.recruiter_phone.match(telformart)) {
        if (editData.website_url) {
          if (editData.website_url.match(urlformat)) {
            props.onModalSubmit(editData, "edit");
            clearData();
          } else {
            setShowError(true);
            toastr.error("Enter Valid Website URL");
          }
        } else {
          props.onModalSubmit(editData, "edit");
          clearData();
        }
      } else {
        setShowError(true);
        toastr.error("Enter Valid Mobile Number");
      }
    } else {
      setShowError(true);
      toastr.error("Enter Valid Email");
    }
    // ApiService.addCollege(collegeDetails).then((res) => {
    //   if(res && res.status === 200 && res.data.success){

    //   }else{
    //       toastr.error("Something Went Wrong", "", { timeOut: 2000 });
    //   }
    // });

    // console.log(editData)
  };
  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      size="lg"
      className="edit-profile-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Edit College Profile
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>College Logo</Label>
              <div className="d-flex">
                <div className="profile-logo-container">
                  <img
                    src={profilepic ? profilepic : default_company_logo}
                    alt=""
                    className="avatar-md img-thumbnail rounded-circle"
                  />
                </div>
                <div className="upload-section">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="font-size-12">
                            <button
                              className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                              type="button"
                            >
                              <img src={cloudOutline} alt="cloud" />{" "}
                              <span className="pl-1">UPLOAD PHOTO</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {profilepic && (
                    <div className="pt-1 pl-1">
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                        type="button"
                        onClick={() => removeProfilePic()}
                      >
                        <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required" />
                        <span className="pl-1 label-required">
                          REMOVE PHOTO{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">College Name</Label>
              <Input
                type="text"
                value={fname || ""}
                disabled={true}
                onChange={(e) => OnchangeHandler(e.target.value, "fname")}
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Email Address
                <Label style={{ color: "red" }} required>
                  *
                </Label>{" "}
              </Label>
              <Input
                type="email"
                value={editData ? editData.recruiter_email : ""}
                onChange={(e) =>
                  OnchangeHandler(e.target.value, "recruiter_email")
                }
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkEmail(editData.recruiter_email)}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Phone Number
                <Label style={{ color: "red" }} required>
                  *
                </Label>{" "}
              </Label>
              <Row>
                <Col lg="5" xs="5" className="pr-0">
                  <select
                    className="form-control phone-number-select"
                    value={isdCode || ""}
                    onChange={(event) => {
                      OnchangeHandler(event.target.value, "isd_code");
                    }}
                  >
                    {getCountries().map((country) => (
                      <option key={country} value={country}>
                        {country} +{getCountryCallingCode(country)}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="7" xs="7">
                  {/* <PhoneInput
                    country={country ? country : "IN"}
                    international
                    value={mobileNumber}
                    onChange={(v) => OnchangeHandler(v, "mobileNumber")}
                    className="form-control phone-number-input"
                  /> */}
                  <Input
                    type="tel"
                    maxLength={10}
                    value={editData ? editData.recruiter_phone : ""}
                    onChange={(e) =>
                      OnchangeHandler(e.target.value, "recruiter_phone")
                    }
                  />
                </Col>
                {showError && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkmobileNumber(editData.recruiter_phone)}
                  </label>
                )}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                College Location
                <Label style={{ color: "red" }} required>
                  *
                </Label>
              </Label>
              {/* <Input
                type="text"
                value={location || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "location")}
              /> */}
              <Input
                type="select"
                value={editData ? editData.location : ""}
                onChange={(event) =>
                  OnchangeHandler(event.target.value, "location")
                }
                name="select"
                id="exampleCity"
              >
                <option>Select</option>
                <option>Mumbai</option>
                <option>Delhi</option>
                <option>Bangalore</option>
                <option>Hyderabad</option>
                <option>Ahmedabad</option>
                <option>Chennai</option>
                <option>Kolkata</option>
                <option>Surat</option>
                <option>Pune</option>
                <option>Jaipur</option>
                <option>Lucknow</option>
                <option>Kanpur</option>
                <option>Nagpur</option>
                <option>Indore</option>
                <option>Thane</option>
                <option>Bhopal</option>
                <option>Visakhapatnam</option>
                <option>Pimpri-Chinchwad</option>
                <option>Patna</option>
                <option>Vadodara</option>
                <option>Ghaziabad</option>
                <option>Ludhiana</option>
                <option>Agra</option>
                <option>Nashik</option>
                <option>Faridabad</option>
                <option>Meerut</option>
                <option>Rajkot</option>
                <option>Kalyan-Dombivali</option>
                <option>Vasai-Vira</option>
                <option>Varanasi</option>
                <option>Srinagar</option>
                <option>Aurangabad</option>
                <option>Dhanbad</option>
                <option>Amritsar</option>
                <option>Navi Mumbai</option>
                <option>Allahabad</option>
                <option>Ranchi</option>
                <option>Howrah</option>
                <option>Coimbatore</option>
                <option>Jabalpur</option>
                <option>Gwalior</option>
                <option>Vijayawada</option>
                <option>Jodhpur</option>
                <option>Madurai</option>
                <option>Raipur</option>
                <option>Kota</option>
              </Input>
            </FormGroup>
            {showError &&
              (editData.location !== "" || editData.location !== "Select") && (
                <label style={{ "margin-bottom": "0", color: "red" }}>{}</label>
              )}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Website URL{" "}
                <span className="font-size-11 text-muted font-weight-ligt">
                  (Optional)
                </span>
              </Label>
              <Input
                type="text"
                value={editData ? editData.website_url : ""}
                onChange={(e) => OnchangeHandler(e.target.value, "website_url")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-green waves-effect"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          disabled={
            !checkmobileNumber(editData ? editData.recruiter_phone : 0).valid ||
            !checkEmail(editData ? editData.recruiter_email : "").valid
          }
          type="button"
          className="btn bg-green waves-effect waves-light"
          onClick={() => {
            savechanges();
          }}
        >
          Save{" "}
        </button>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
