import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ApiService from "../../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const InviteUserModal = (props) => {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const [company, setCompany] = useState();
  const [list, setList] = useState(0);
  function handleValidSubmit() {
    // props.onModalSubmit({ mail, type:type.toLowerCase(), company });

    console.log(emails);
    var mail = "";
    if (value && !emails) {
      if (isValid(value)) {
        mail = value;
        setList(0);
      }
    } else if (value && emails) {
      if (isValid(value)) {
        console.log("value checked");
        console.log(value);
        // setEmails((emails) => [...emails, value]);
        emails.push(value);
        setValue("");
      }

      console.log("value checked11");
      mail = emails;

      setList(0);
    } else {
      mail = emails;
    }
    console.log("HI");
    console.log(mail);

    if ((value && isValid(value)) || !value) {
      sentInvites(mail);
    }
  }

  function sentInvites(mail) {
    ApiService.invite({ email: mail, role: "student" }).then((res) => {
      if (res && res.status === 200) {
        if (res.validated || res.data.success == "true") {
          console.log(res);
          props.setInvited((prevState)=>{
            return prevState+mail.length
          })
          props.tog_center();

          toastr.success("Invitation Sent Successfully", "", {
            timeOut: 2000,
          });
        } else {
          toastr.error(res.data.message, "", { timeOut: 2000 });
          setEmails([]);
        }
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isInList = (email) => {
    return emails.includes(email);
  };
  const isValid = (email) => {
    var err = null;

    if (!isEmail(email)) {
      err = `${email} is not a valid email address.`;
    }

    // if (isInList(email)) {
    //   err = `${email} has already been added.`;
    // }

    if (err) {
      setError(err);

      return false;
    }

    return true;
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " ", ";"].includes(evt.key)) {
      evt.preventDefault();

      if (value.includes(" ") || value.includes(",") || value.includes(";")) {
        var mails = value.split(/[; ,]+/);
        mails.forEach((item) => {
          if (isValid(item)) {
            setEmails((emails) => [...emails, item]);
            setList(1);
          }
        });
      } else {
        var email = value.trim();

        if (email && isValid(email)) {
          setEmails((emails) => [...emails, email]);
        }
      }
      setValue("");
    }
  };

  const handleDelete = (email) => {
    setEmails(
      emails.filter((item) => {
        return email !== item;
      })
    );
  };
  // const handlePaste = (evt) => {
  //   evt.preventDefault();

  //   var paste = evt.clipboardData.getData("text");
  //   var mails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  //   if (mails) {
  //     mails.filter((email) => {
  //       setEmails((emails) => [...emails, email]);
  //     });
  //   }
  // };
  useEffect(() => {
    setType("Client");
    // setMail("");
    setEmails([]);
    setCompany("");
  }, [props.modal_center]);

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="add-user-modal"
      size="lg"
    >
      <AvForm
        className="f"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="modal-header">
          <h6 className="modal-title mt-0 font-size-22" id="myModalLabel">
            Invite User
          </h6>
          <button
            type="button"
            onClick={() => {
              props.tog_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col lg="6">
              {/* <AvField type="select" name="select" 
                            label="Role*" 
                            required
                            value={type || ''}
                            onChange={e => setType(e.target.value)}
                        >
                            <option>Admin</option>
                            <option>Client</option>
                            <option>User</option>
                        </AvField> */}
              <AvField
                name="name"
                label="Role"
                disabled={true}
                placeholder=""
                type="text"
                required
                value="Student"
                onChange={({ target }) => {
                  setType(target.value);
                }}
              />
            </Col>
          </Row>
          {/* <Row>
            <Col lg="6">
              <AvField
                className="mt-3 mb-3"
                name="email"
                label="Email Address*"
                placeholder="Type email address"
                type="text"
                required
                onChange={({ target }) => {
                  setMail(target.value);
                }}
                value={mail || ""}
              />
            </Col>
          </Row> */}
          <Row>
            <Col lg="10">
              <p className="mailtext">
                Copy and paste all the email addresses from your existing excel
                sheet to invite multiple students at once
              </p>
              <AvField
                className="mt-3 mb-3 mail"
                name="email"
                placeholder="Type name or email address"
                type="text"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  setError("");
                }}
                onKeyDown={handleKeyDown}
                style={{ backgroundColor: "#f6f6f6" }}
                // onPaste={handlePaste}
              />
              {error && <p className="error">{error}</p>}
              {emails &&
                emails.map((email) => (
                  <div key={email} className="emailchip">
                    <span className="emailchiptext">{email}</span>{" "}
                    <button
                      type="button"
                      onClick={() => handleDelete(email)}
                      className="close"
                    >
                      &times;
                    </button>
                  </div>
                ))}
            </Col>
          </Row>
          {/* {type === 'Client' && <Row >
                    <Col lg="6">
                        <AvField className='mt-3 mb-3' name="company"  
                            label="Company*"  type="text" required
                             onChange={({ target }) => { setCompany(target.value);}} 
                             value={company || ''}
                        />
                    </Col>
                </Row>
                } */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.tog_center();
            }}
            className="btn waves-effect cancelbtn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!emails.length && !value ? true : false}
            className="btn bg-green waves-effect waves-light  submitbtn"
            // onClick={() => onModalSubmit()}
          >
            Invite
          </button>
        </div>
      </AvForm>
    </Modal>
  );
};

export default InviteUserModal;
