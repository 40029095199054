import React, { useState } from "react";

import { connect } from "react-redux";

import { Link, NavLink, useHistory } from "react-router-dom";

// Redux Store
import { toggleLeftmenu } from "../../redux/actions/layout";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
import logo from "../../assets/images/Zunavish_Logo_SVG.svg";
import Common from "../../helpers/common";

const Header = (props) => {
  const [menu, setMenu] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [studentlist, setStudentList] = useState("");
  function startSearch(value) {
    ApiService.getStudentsList(value).then((res) => {
      if (res && res.status === 200) {
        setStudentList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  let history = useHistory();
  function toStudents(id) {
    history.push(`/public/profile/${id}`);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <img src={logo} alt="" />
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="19" />
                </span>
              </Link>
            </div>

            {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => { props.toggleLeftmenu(!props.leftMenu); }}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button> */}
          </div>
          <div className="d-flex justify-content-start flex-grow-1">
            <form
              className="app-search d-none d-lg-block"
              // onBlur={() => {
              //   setStudentList("");
              // }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Students By Email"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                    if (search) {
                      startSearch(e.target.value);
                    } else {
                      setStudentList("");
                    }
                  }}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              <div
                className={
                  search && studentlist
                    ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                {studentlist.students ? (
                  <ul
                    style={{
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    {studentlist &&
                      studentlist.students &&
                      studentlist.students.length > 0 &&
                      studentlist.students
                        .filter((item) => {
                          if (search == "") {
                            console.log("IF");
                          } else if (
                            item.email
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          console.log(item);
                          if (item.is_active) {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  console.log("HI");
                                  toStudents(item.UserId);
                                }}
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.title ? item.title : item.email}
                              </li>
                            );
                          } else {
                            return (
                              <li
                                key={index}
                                onClick={() => {
                                  console.log("HI");
                                  // toStudents(item.id);
                                  toastr.error(
                                    "User invited but not active yet!",
                                    "",
                                    {
                                      timeOut: 2000,
                                    }
                                  );
                                }}
                                style={{
                                  padding: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.title ? item.title : item.email}
                              </li>
                            );
                          }
                        })}
                  </ul>
                ) : (
                  <ul
                    style={{
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    <li
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {studentlist.message}
                    </li>
                  </ul>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex align-items-center justify-content-center font-size-14 header-font">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                onClick={() => setIsSearch(!isSearch)}
                // onBlur={() => setIsSearch(!isSearch)}
                // onChange={(e) => {
                //   setSearch(e.target.value);
                //   console.log(e.target.value);
                //   if (search) {
                //     startSearch();
                //   } else {
                //     setStudentList("");
                //   }
                // }}
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form
                  className="p-3"
                  // onBlur={() => {
                  //   setStudentList("");
                  // }}
                >
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Students By Email"
                        aria-label="Recipient's username"
                        onChange={(e) => {
                          e.target.value = Common.trimStart(e.target.value);
                          setSearch(e.target.value);
                          console.log(e.target.value);
                          if (search) {
                            startSearch(e.target.value);
                          } else {
                            setStudentList("");
                          }
                        }}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      isSearch && search && studentlist
                        ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                  >
                    {studentlist.students ? (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        {studentlist &&
                          studentlist.students &&
                          studentlist.students.length > 0 &&
                          studentlist.students
                            .filter((item) => {
                              if (search == "") {
                                console.log("IF");
                              } else if (
                                item.email
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => {
                              console.log(item);
                              if (item.is_active) {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      console.log("HI");
                                      toStudents(item.UserId);
                                    }}
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.title ? item.title : item.email}
                                  </li>
                                );
                              } else {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      console.log("HI");
                                      // toStudents(item.id);
                                      toastr.error(
                                        "User invited but not active yet!",
                                        "",
                                        {
                                          timeOut: 2000,
                                        }
                                      );
                                    }}
                                    style={{
                                      padding: "5px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {item.title ? item.title : item.email}
                                  </li>
                                );
                              }
                            })}
                      </ul>
                    ) : (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        <li
                          style={{
                            padding: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {studentlist.message}
                        </li>
                      </ul>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="d-inline-block pl-5">
              
              </div>
              <div className="d-inline-block pl-5">
                              
              </div> */}
            <div className="d-inline-block d-lg-none pr-2 font-size-18 green-text-color cursor-pointer">
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block header-dropdown-add"
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  tag="button"
                >
                  <i className="fa fa-angle-down header_down_arrow"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" href="/collegeDashboard">
                    Home
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="d-none d-lg-block pl-2 pr-2">
              <NavLink
                className="header-item"
                activeClassName="active"
                to="/collegeDashboard"
              >
                Home
              </NavLink>
            </div>
            <div
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/notifications")}
            >
              <NotificationDropdown />
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(Header);
