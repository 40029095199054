import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../redux/actions/layout";

// Other Layout related Component
import Header from "./Header";
//import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false
    };
  }


  componentDidMount() {

    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";

      setTimeout(function () {

        document.getElementById('preloader').style.display = "none";
        document.getElementById('status').style.display = "none";
      }, 2500);
    }
    else {
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);

    const title = this.props.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage;

    this.props.changeLayout('horizontal');
    
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
   
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = e => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  getHeader = () =>{
    if(this.props.location && (this.props.location.pathname === '/setpassword' 
      || this.props.location.pathname === '/verification' || this.props.location.pathname === '/collegeOnboarding'
      || this.props.location.pathname === '/dashboard' || this.props.location.pathname === '/studentOnboarding')){
      return '';
    }else{
      return (
        <Header theme={this.props.topbarTheme}
        isMenuOpened={this.state.isMenuOpened}
        openLeftMenuCallBack={this.openMenu} />
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
        <div id="layout-wrapper">
          {this.getHeader()}
          <div className="main-content">
            {this.props.children}
          </div>
        </div>

      </React.Fragment>
    );
  }
}
const mapStatetoProps = state => {
  return {
    ...state.layout
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,  changeLayout, changeLayoutWidth
})(withRouter(Layout));