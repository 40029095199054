import React from "react";

import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";

const Verification = (props) => {
  // handleValidSubmit
  function handleValidSubmit() {
    props.history.push("login");
  }
  return (
    <React.Fragment>
      <div className="account-pages">
        <div className="float-left left-section">
          <Row>
            <Col className="col-12">
              <div className="pl-4 pb-2">
                <h1 className="font-size-36 font-weight-bold">
                  {" "}
                  Check Your Inbox{" "}
                </h1>
              </div>
              <div className="pl-4 font-size-16 pr-5  ">
                We just emailed a verification link to {props.email}. Click the
                link to reset your forgotten password.
              </div>
              <div className="pl-4">
                <div className="mt-3 text-left border-bottom pb-3">
                  <button
                    className="btn bg-green waves-effect waves-light w-md "
                    onClick={handleValidSubmit}
                    type="submit"
                  >
                    Done
                  </button>
                </div>
              </div>
              
            </Col>
          </Row>
        </div>
        <div className="float-left right-section">
          <div className="text-container">
            <div className="company-text-bold">
              <img src={text_logo} alt="text" />
            </div>
            <div className="mt-2 text-center">
              vivify your business and People
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    email: state.auth.email || "Invalid",
  };
};
export default withRouter(connect(mapStateToProps, {})(Verification));
