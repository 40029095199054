import api from "../../config/axios";
import {
  SIGNUP_EMAIL,
  SIGNUP_GOOGLE,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  PASSWORD_SET_SUCCESS,
  FORGOT_PASSWORD,
  PASSWORD_RESET_SUCCESS,
  NEW_PAGE,
} from "../auth";


export const signupWithEmail = (email, history) => async (dispatch) => {
  localStorage.setItem("token", "");
  console.log("here");
  dispatch({
    type: SIGNUP_EMAIL,
  });
  const response = await api
    .post("api/users/signup/email/", { email, role: "college" })
    .then((response) => {
      dispatch({
        type: SIGNUP_EMAIL,
        payload: email,
      });
      if (response.status === 200 && response.data.success === "true") {
        history.push("/verification");
      } else {
        dispatch({
          type: API_ERROR,
          payload: response.data.message,
        });
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: API_ERROR,
          payload: err.response.data.message,
        });
      } else {
        dispatch({
          type: API_ERROR,
          payload: "Unable to reach to Servers",
        });
      }
    });
};

export const newPage = () => (dispatch) => {
  dispatch({
    type: NEW_PAGE,
  });
};

export const signupWithGoogle = (idToken, history) => async (dispatch) => {
  dispatch({
    type: SIGNUP_GOOGLE,
  });

  const response = await api
    .post("api/users/signup/google/", {
      idToken,
      role: "college",
    })
    .then((res) => {
      const token = res.data.authToken;
      localStorage.setItem("token", token);
      history.push("/aboutyourself");
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response.status);
        dispatch({
          type: API_ERROR,
          payload: err.response.data.message,
        });
      } else if (err.request) {
        // Request was made but no response
        dispatch({
          type: API_ERROR,
          payload: "Unable to reach to servers",
        });
      } else {
        dispatch({
          type: API_ERROR,
          payload: "Unable to reach to servers",
        });
      }
    });
};

export const setpassword = (
  password,
  confirmpassword,
  token,
  history
) => async (dispatch) => {
  dispatch({
    type: PASSWORD_SET_SUCCESS,
  });

  try {
    const response = await api.post("/api/college/signup", {
      password: password,
      confirmPassword: confirmpassword,
      token: token,
      role: "college",
    });
    console.log("response", response);
    if (response.status === 200) {
      if (response.data.success) {
        localStorage.setItem("token", response.data.authToken);
        history.push("/collegeOnboarding");
      } else {
        dispatch({
          type: API_ERROR,
          payload:response.data.reason || response.data.message
        });
      }
    } else {
      dispatch({
        type: API_ERROR,
      });
    }
  } catch {
    dispatch({
      type: API_ERROR,
    });
  }
};

export const loginUser = (email, password, history) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
  });
  api
    .post(
      "/api/college/signin",
      {
        email: email,
        password: password,
        role: "college",
      },
      {
        headers: {
          "content-type": "application/json",
          "Cache-Control": "no-cache",
        },
      }
    )
    .then((res) => {
      if (res.status === 200 && res.data.success === true) {
        localStorage.setItem("token", res.data.authToken);
        localStorage.setItem("onboarding", res.data.onboarding);
        dispatch({
          type: LOGIN_SUCCESS,
        });
        history.push("/collegeDashboard");
      } else {
        dispatch({
          type: API_ERROR,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: API_ERROR,
        payload: err.response.data.message,
      });
      if (err.response) {
        if (err.response.status === 404) {
          console.log("Error: Page Not Found");
        } else {
          console.log(err.response.data.reason);
        }
      } else if (err.request) {
        // Request was made but no response
        console.log(err.request);
      } else {
        console.log(err.message);
      }
    });
  //Add async action
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const ResetPassword = (
  password,
  confirmpassword,
  token,
  history
) => async (dispatch) => {
  dispatch({
    type: PASSWORD_RESET_SUCCESS,
  });
    const response = await api.post(
      "api/college/password/reset/check_password/",
      {
        password: password,
        confirmPassword: confirmpassword,
        token: token,
        role: "college",
      }
    ).then((res) => {
      if (res.status === 200 && res.data.success === true) {
        localStorage.setItem("token", res.data.authToken);
        localStorage.setItem("onboarding", res.data.onboarding);
        dispatch({
          type: LOGIN_SUCCESS,
        });
        history.push("/collegeDashboard");
      } else {
        dispatch({
          type: API_ERROR,
          payload: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: API_ERROR,
        payload: err.response.data.message,
      });
      if (err.response) {
        if (err.response.status === 404) {
          console.log("Error: Page Not Found");
        } else {
          console.log(err.response.data.reason);
        }
      } else if (err.request) {
        // Request was made but no response
        console.log(err.request);
      } else {
        console.log(err.message);
      }
    });
    
};
export const forgotpassword = (email, history) => async (dispatch) => {
  localStorage.setItem("token", "");
  dispatch({
    type: FORGOT_PASSWORD,
  });
  const response = await api
    .post("api/college/password/reset/", { email, role: "college" })
    .then((response) => {
      console.log(response);
      dispatch({
        type: FORGOT_PASSWORD,
        payload: email,
      });
      if (response.status === 200 && response.data.success === "true") {
        history.push("/passwordreset");
      } else {
        dispatch({
          type: API_ERROR,
          payload: response.data.error,
        });
      }
    })
    .catch((err) => {
      console.log(err.response);
      if (err.response) {
        dispatch({
          type: API_ERROR,
          payload: err.response.data.message,
        });
      } else {
        dispatch({
          type: API_ERROR,
          payload: "Unable to reach to Servers",
        });
      }
    });
};
