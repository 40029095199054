import React, { useEffect, useState } from "react";
import {
  Media,
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
} from "reactstrap";
import post_job_icon from "../../../assets/images/post_job_icon.png";
import classnames from "classnames";
import toastr from "toastr";
import axios from "axios";

import "toastr/build/toastr.min.css";
import InviteUserModal from "./InviteUserModal";
import import_icon from "../../../assets/images/importicon.svg";
// Redux
//import { connect } from 'react-redux';
import { withRouter, useHistory } from "react-router-dom";
import ApiService from "../../../config/restapi";
import Dropzone from "react-dropzone";
import Common from "../../../helpers/common";

const dummyData = [
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
  {
    name: "Naga Sai Ram",
    email: "sairam@gmail.com",
    degree: "B.Tech",
    course: "Computer Science",
    active_intervews: "3",
    accepted_offer: "2",
    total_interviews: "5",
    addedOn: "July 20, 2020",
  },
];

const CollegeDashboard = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [modal_center, setmodal_center] = useState(false);
  const [jobList, setJobList] = useState();
  const [list, setList] = useState();
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [active, setActive] = useState();
  const [invited, setInvited] = useState();

  const [search, setSearch] = useState("");

  const [penddingList, setPenddingList] = useState([]);
  const [studentList, setStudentList] = useState({ active: [], inactive: [] });

  useEffect(() => {
    getlist(1, true);
    getlength();
  }, []);

  function getlist(offset, active) {
    ApiService.getStudents(offset, active).then((res) => {
      if (res && res.status === 200 && res.data) {
        // console.log(res);
        // console.log("From back listssss");

        setJobList(res.data.students);
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  function getlength() {
    ApiService.getStudentsLength(0, true).then((res) => {
      if (res && res.status === 200 && res.data) {
        // console.log(res);
        // console.log("From back length");
        setActive(res.data.active);
        setInvited(res.data.invited);
      } else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }
  useEffect(() => {
    if (activeTab === "1") {
      getlist(1, true);
    } else if (activeTab === "2") {
      getlist(1, false);
    }
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setLoading(false);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    var off = jobList
      ? jobList.length % 10 == 0
        ? jobList.length / 10 + 1
        : Math.floor(jobList.length / 10) + 1
      : 1;
    var active = activeTab === "1" ? true : false;
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading ||
      loaded
    )
      return;
    if (!loading) {
      setLoading(true);
      if (!loaded) {
        ApiService.getStudents(off, active).then((res) => {
          if (res && res.status === 200 && res.data) {
            console.log(res);
            console.log("From back listssss in scroll");

            res.data.students ? setLoaded(false) : setLoaded(true);
            // setList(res.data.students);
            console.log("lets see");
            res.data &&
              res.data.students &&
              res.data.students.forEach((element) => {
                setJobList((jobList) => [...jobList, { ...element }]);
              });

            console.log("hi");
          } else {
            toastr.error("Something Went Wrong", "", { timeOut: 2000 });
          }
        });
      }
    }
  };

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  let history = useHistory();
  function toStudents(id) {
    history.push(`/public/profile/${id}`);
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function remind(id) {
    const headers = {
      "Content-Type": "application/json",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: `${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        `https://api.zunavish.com/api/college/student/remind`,
        { id: id },
        headers
      )
      .then((res) => {
        if (res && res.status === 200) {
          toastr.success("Sent Reminder Successfully");
        } else {
          toastr.error("Something went wrong");
        }
      });
  }
  const updateInviteUser = () => {};

  function changeMenuState(index) {
    let tempData = [...jobList];
    tempData[index].menu = !tempData[index]["menu"];
    setJobList([...tempData]);
  }

  function openDropDownRow(index) {
    let tempData = [...jobList];
    tempData[index]["isexpand"] = !tempData[index]["isexpand"];
    setJobList([...tempData]);
  }

  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "csv") {
      return true;
    }
    return false;
  }

  function handleAcceptedFiles(files) {
    if (validateFileExtention(files)) {
      const formData = new FormData();
      formData.append("file", files[0]);
      console.log(files);
      ApiService.importcsv(formData).then((res) => {
        if (res && res.status === 200 && res.data) {
          toastr.success("imported Successfully", "", {
            timeOut: 2000,
          });
        }
      });
    } else {
      toastr.error("Please Upload Valid Format", "", { timeOut: 2000 });
    }
  }

  return (
    <React.Fragment>
      // {console.log("Job List")}
      // {console.log(jobList)}
      // {console.log("List")}
      // {console.log(list)}
      <div className="page-content job-dashbord">
        <Row>
          {console.log("props")}
          {console.log(props)}
          <Col
            xl="6"
            className="pl-4 pr-4 text-left green-text-color font-weight-medium"
          >
            Students
          </Col>
          <Col
            xl="6"
            className="pl-4 pr-4 text-right flexbox mobile_dashboard_btn"
          >
            {/* <button
                            className='btn btn-outline-shadow waves-effect waves-light w-md mr-2'
                            type="button"
                            onClick={() => props.history.push('/jobwizard')}
                        ><img className='mr-2' src={post_job_icon} alt='job-post' /> IMPORT CSV
                        </button> */}
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span className="font-size-12">
                    <button
                      className="btn btn-outline-shadow waves-effect waves-light w-md mr-2"
                      type="button"
                    >
                      <img className="mr-2" src={import_icon} alt="job-post" />{" "}
                      <span className="pl-1">IMPORT CSV</span>
                    </button>
                  </span>
                </div>
              )}
            </Dropzone>
            <button
              className="btn btn-outline-shadow waves-effect waves-light w-md"
              type="button"
              onClick={() => tog_center()}
            >
              <img className="mr-2" src={post_job_icon} alt="job-post" /> INVITE
              STUDENTS
            </button>
          </Col>
          <InviteUserModal
            modal_center={modal_center}
            centered={true}
            tog_center={() => tog_center()}
            onModalSubmit={updateInviteUser}
            setInvited={setInvited}
          />
        </Row>
        <Col xl="16" lg="16" className="text-left mt-2">
          <Col style={{padding:"0px"}}>
            <div className="job-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Active ({active ? active : "0"})
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Invited ({invited ? invited : "0"})
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Col>

        {props.isMobile ? (
          <Row className="mt-2">
            <Row style={{ margin: "10px" }}>
              <Col lg="16" s="16" className="text-left">
                <div className="form-group m-0 ">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Students By Name"
                      aria-label="Recipient's username"
                      onChange={(e) => {
                        e.target.value = Common.trimStart(e.target.value);
                        setSearch(e.target.value);
                        // console.log(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Col lg="12" className="pl-4 pr-4 text-left">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"> Name</th>
                      <th scope="col"> Email Address</th>
                      <th scope="col">Added On</th>
                      <th className="text-center">
                        {/* <i className="bx bx-cog font-size-17 align-middle mr-1"></i> */}
                      </th>
                    </tr>
                  </thead>
                  {jobList &&
                    jobList
                      .filter((item) => {
                        if (search == "") {
                          return item;
                        } else if (
                          item.email
                            .toString()
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          (item &&
                            item.User &&
                            item.User.name &&
                            item.User.name
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase()))
                        ) {
                          return item;
                        }
                      })
                      .map((item, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <div className="font-size-16">
                                  {item && item.User && item.User.name
                                    ? item.User.name
                                    : "--"}
                                </div>
                              </td>
                              <td>
                                <div className="font-size-16">{item.email}</div>
                              </td>
                              <td>
                                <div className="font-size-16">
                                  {item &&
                                    item.createdAt &&
                                    item.createdAt.toString().split("T")[0]}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          // <Row className="mt-2">
          //     <Col xl="12" className="pl-4 pr-4 text-left">
          //         <Row className="row-header font-weight-medium font-size-16">
          //             <div className="email-w pl-2 pr-2">Email Address</div>
          //         </Row>
          //     </Col>
          //     <Col xl="12" className="pl-4 pr-4 text-left">
          //         {jobList &&
          //             jobList.map((item, index) => {
          //                 return (
          //                     <Row key={index} className="mt-2 text-left">
          //                         <Col xl="12">
          //                             <Row
          //                                 className="row-items green-text-color cursor-pointer"
          //                                 onClick={() => { }}
          //                             >
          //                                 <div className="name-w pl-2 pr-2">
          //                                     <div className="font-size-16">{item.email}</div>
          //                                 </div>
          //                             </Row>
          //                         </Col>
          //                     </Row>
          //                 );
          //             })}
          //     </Col>
          // </Row>

          <Col xl="16" lg="16" className="text-left">
            <Row style={{ margin: "10px 0px" }}>
              <Col xl="3" className="text-left pl-0">
                <div className="form-group m-0 ">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Students By Name"
                      aria-label="Recipient's username"
                      onChange={(e) => {
                        e.target.value = Common.trimStart(e.target.value);
                        setSearch(e.target.value);
                        // console.log(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <span className="bx bx-search-alt"></span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="row-header font-weight-medium font-size-16">
              <Col xl="3" lg="3" className="font-size-14">
                Name
              </Col>
              <Col xl="4" lg="4" className="font-size-14">
                Email
              </Col>
              <Col xl="3" lg="3" className="font-size-14">
                Added On
              </Col>

              {/* <Col className="setting_w">
                                    <i className="bx bx-cog font-size-17 align-middle"></i>
                                </Col> */}
            </Row>

            {jobList &&
              jobList
                .filter((item) => {
                  if (search == "") {
                    return item;
                  } else if (
                    item.email
                      .toString()
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    (item &&
                      item.User &&
                      item.User.name &&
                      item.User.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()))
                  ) {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <Row key={index} className="mt-2 font-weight-normal">
                      <Col xl="12" lg="12" className="text-left">
                        <Row className="row-items green-text-color">
                          <Col xl="3" lg="3" className="">
                            <span className="font-size-14 ml-2">
                              {item && item.User && item.User.name
                                ? item.User.name
                                : "--"}
                            </span>
                          </Col>

                          <Col xl="4" lg="4" className="">
                            <div className="font-size-14">{item.email}</div>
                          </Col>

                          <Col xl="3" lg="3" className="">
                            <div className="font-size-14">
                              {item &&
                                item.createdAt &&
                                item.createdAt.toString().split("T")[0]}
                            </div>
                          </Col>

                          <Col className="setting_w">
                            <Dropdown
                              isOpen={item.menu}
                              toggle={() => changeMenuState(index)}
                              className="d-inline-block"
                            >
                              <DropdownToggle
                                className="btn header-item waves-effect"
                                id="page-header-user-dropdown"
                                tag="button"
                              >
                                <i className="bx bx-dots-vertical-rounded green-text-color font-size-18 cursor-pointer"></i>
                              </DropdownToggle>
                              <DropdownMenu className="green-text-color" right>
                                <DropdownItem
                                  className="green-text-color"
                                  onClick={() =>
                                    history.push(
                                      `/public/profile/${item.UserId}`
                                    )
                                  }
                                >
                                  View Profile
                                </DropdownItem>
                                <DropdownItem
                                  className="green-text-color"
                                  onClick={() => remind(item.UserId)}
                                >
                                  Complete Profile Reminder
                                </DropdownItem>

                                {/* <DropdownItem
                                    className="green-text-color"
                                    tag="a"
                                    href="#"
                                    onClick={(e) => OpenRejectionModal(e)}
                                  >
                                    Block User
                                  </DropdownItem> */}
                              </DropdownMenu>
                            </Dropdown>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
          </Col>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(CollegeDashboard);
